.route-projects-edit {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    h1 {
      color: white;
      font-size: 24px;
      line-height: 55px;
      font-weight: normal;
      letter-spacing: -0.02em;
      display: inline-block;
      vertical-align: top;
      margin: 0 0 0 5px;
    }
    a {
      color: white;
      font-weight: normal;
      font-size: 16px;
      display: inline-block;
      margin-top: 15px;
    }
  }
  .project-list {
    padding-bottom: 95px;
    .project {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .delete {
        margin-right: 15px;
        span {
          margin-top: 21px;
          width: 30px;
          height: 30px;
          border: 6px solid white;
          background-color: #ED6D6D;
          border-radius: 50%;
          display: block;
          color: white;
          text-align: center;
          line-height: 16px;
        }
      }
      .move {
        margin-left: 15px;
        width: 30px;
      }
      & + .project {
        margin-top: 15px;
      }
    }
    .project-body {
      background: #FFFFFF;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      padding: 15px;
      display: block;
      flex-grow: 1;
      div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      strong {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #717171;
        display: block;
        margin-bottom: 5px;
      }
      small {
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #717171;

        max-width: 225px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.route-project-details {
  .layout > .row {
    min-height: 100vh;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 65px;
    h1 {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.02em;
      color: #FFFFFF;
      display: inline-block;
      margin: 0;
      position: relative;
      top: 6px;
      small {
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #D8D8D8;
        display: block;
        width: 170px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .back {
      color: white;
      font-weight: normal;
      display: inline-block;
      margin-top: 22px;
      margin-right: 13px;
      width: 10px;
      height: 20px;
      background-position: left center;
      background-repeat: no-repeat;
      background-image: url('../assets/icon_back.png');
    }
    .users {
      margin-top: 15px;
      display: block;
      span {
        display: inline-block;
        height: 34px;
        width: 34px;
        text-align: center;
        border-radius: 50%;
      }
      .user {
        border: 3px solid #7b3bf5;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 28px;
        vertical-align: middle;
        background-color: #18A0FB;
        color: white;
        margin-right: -10px;
        position: relative;
        z-index: 5;
        & + .user {
          background-color: #7D89FC;
          z-index: 4;
          & + .user {
            background-color: #E8637B;
            z-index: 3;
            & + .user {
              background-color: #7B39F5;
              z-index: 2;
            }
          }
        }
      }
      .add {
        background-color: #E9ECEE;
        color: #717171;
        line-height: 26px;
        font-size: 20px;
        border: 3px solid #7b3bf5;
        vertical-align: middle;
        position: relative;
        z-index: 1;
      }
    }
  }
  section {
    background-color: white;
    min-height: calc(100vh - 65px);
    margin-left: -15px;
    margin-right: -15px;
    padding: 15px 15px 95px;
    & > .row {
      margin-left: -5px;
      margin-right: -5px;
      & > .col {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
    h2 {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: #717171;
    }
    h3 {
      font-weight: bold;
      font-size: 11px;
      line-height: 30px;
      letter-spacing: 0.07em;
      color: #909090;
      text-transform: uppercase;
    }
    .toggle-summary {
      float: right;
      width: 24px;
      height: 20px;
      padding: 0;
      border: none;
      background: none transparent;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../assets/icon_toggle_up.png');
      &.down {
        background-image: url('../assets/icon_toggle_down.png');
      }
    }
    #summary-chart {
      .highcharts-credits {
        display: none;
      }
      .highcharts-tooltip {
        text-align: center;
        border: none;
        & > span {
          background-color: white;
        }
      }
      .date {
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        color: #717171;
      }
      .values {
        display: inline-block;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #18A0FB;
        b {
          font-weight: normal;
          text-transform: uppercase;
        }
        & + .values {
          color: #7B39F5;
        }
      }
    }
    .summary-legend {
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      color: #717171;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 15px;
      padding: 0 15px;
      span {
        width: 25px;
        height: 3px;
        display: inline-block;
        margin-right: 7px;
        position: relative;
        top: -3px;
      }
      .actual span {
        background-color: #18A0FB;
      }
      .forecast span {
        height: 0;
        border-top: 3px dashed #18A0FB;
      }
      .budget span {
        background-color: #7B39F5;
      }
    }
    .summary-units {
      background: #DDDDDD;
      border: 2px solid #DDDDDD;
      border-radius: 6px;
      padding: 2px;
      display: flex;
      flex-direction: row;
      margin-top: 15px;
      margin-bottom: 20px;
      .btn {
        box-shadow: none;
        border-radius: 5px;
        border: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        padding: 8px;
        color: #717171;
        display: block;
        width: 100%;
        &.active {
          background: #7D89FC;
          color: white;
          &:focus,
          &:active,
          &:active:focus {
            outline: none;
            box-shadow: none;
          }
        }
        &:focus,
        &:active,
        &:active:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
    .summary-timeframe {
      background: #DDDDDD;
      border-radius: 6px;
      border: 2px solid #DDDDDD;
      padding: 2px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 15px;
      .btn {
        box-shadow: none;
        border-radius: 5px;
        border: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        padding: 6px;
        color: #717171;
        display: block;
        width: 20%;
        &.active {
          background: #7D89FC;
          color: white;
          &:focus,
          &:active,
          &:active:focus {
            outline: none;
            box-shadow: none;
          }
        }
        &:focus,
        &:active,
        &:active:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
    .bg {
      background: #F2F2F2;
      border-radius: 16px;
      padding: 15px;
      margin-bottom: 10px;
      display: block;
      h3 {
        margin: 0 0 8px;
        font-weight: bold;
        font-size: 11px;
        line-height: 1;
        letter-spacing: 0.07em;
        color: #929292;
      }
    }
    .budget {
      & > div {
        margin-bottom: 10px;
        strong {
          font-weight: normal;
          font-size: 22px;
          line-height: 1;
          letter-spacing: 0.07em;
          color: #717171;
          text-transform: uppercase;
        }
        div {
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          color: #707781;
        }
      }
      .bar {
        padding: 2px;
        height: 16px;
        background-color: #C4C4C4;
        border-radius: 10px;
        margin: 0;
        div {
          background: #5AC53A;
          border-radius: 10px;
          text-align: right;
          font-size: 9px;
          color: white;
          letter-spacing: 0.07em;
          height: 12px;
          line-height: 12px;
        }
      }
    }
    .status {
      span {
        display: block;
        font-weight: normal;
        font-size: 22px;
        line-height: 30px;
        color: #5AC53A;
        margin-bottom: 56px;
      }
      small {
        display: block;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #707781;
      }
    }
    .timeline {
      strong {
        font-weight: normal;
        font-size: 22px;
        line-height: 30px;
        color: #717171;
        display: block;
        margin-bottom: 45px;
      }
      .details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        & > div {
          flex-grow: 1;
          width: 50%;
          text-transform: uppercase;
          &:last-child {
            text-align: right;
          }
          b {
            display: block;
            font-weight: bold;
            font-size: 13px;
            letter-spacing: normal;
            color: #717171;
          }
          span {
            font-weight: normal;
            font-size: 13px;
            letter-spacing: normal;
            color: #707781;
            display: block;
          }
        }
      }
    }
    .health {
      h3 {
        padding-right: 10px;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: -3px;
          right: 0;
          width: 17px;
          height: 17px;
          background-size: contain;
          background-position: right center;
          background-repeat: no-repeat;
          background-image: url('../assets/icon_info.png');
        }
      }
      div {
        text-align: center;
        background: none transparent;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../assets/health_grey.png');
        padding: 42px 0;
        font-weight: normal;
        font-size: 33px;
        line-height: 30px;
        letter-spacing: 0.07em;
      }
    }
    .budget-projection {
      p {
        margin: 0;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #707781;
      }
    }
    .project-lead {
      & > span {
        float: right;
        font-size: 13px;
        color: #707781;
        position: relative;
        top: -4px;
      }
      .actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        .btn {
          background: #7D89FC;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
          border-radius: 10px;
          text-align: center;
          color: white;
          font-weight: normal;
          font-size: 10px;
          line-height: 12px;
          flex-grow: 1;
          width: 30%;
          padding-top: 30px;
          background-position: center 8px;
          background-repeat: no-repeat;
          background-image: url('../assets/icon_envelope_white.png');
          &:nth-child(2) {
            margin-left: 13px;
            margin-right: 13px;
            background-image: url('../assets/icon_phone.png');
          }
          &:nth-child(3) {
            background-image: url('../assets/icon_chat.png');
          }
        }
      }
    }
    .details {
      h3 {
        margin: 30px 0 15px;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #717171;
        letter-spacing: normal;
        text-transform: none;
      }
      p {
        margin: 0;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #717171;
      }
    }
  }
  .project-carousel {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-top: 1px solid #ddd;
    height: 70px;
    & > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      & > a {
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #7D89FC;
        margin: 12px;
      }
    }
    ul {
      margin: 8px 0 0;
      padding: 0;
      list-style: none;
      li {
        display: inline-block;
        & + li {
          margin-left: 10px;
        }
        &:nth-child(1),
        &:nth-last-child(1) {
          span {
            width: 4px;
            height: 4px;
            margin-top: -6px;
          }
        }
        &:nth-child(2),
        &:nth-last-child(2) {
          span {
            width: 6px;
            height: 6px;
            margin-top: -7px;
          }
        }
      }
      span {
        display: block;
        width: 8px;
        height: 8px;
        background-color: #A0A1A3;
        border-radius: 50%;
        &.active {
          background-color: #7D89FC;
        }
      }
    }
  }
}

.route-project-health {
  .layout > .row {
    min-height: 100vh;
  }
  section {
    background-color: white;
    min-height: calc(100vh - 15px);
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 10px;

    .header {
      border-bottom: 1px solid #ddd;
      padding: 15px;
      position: relative;
      h1 {
        margin: 0;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #717171;
      }
      a {
        position: absolute;
        width: 14px;
        height: 14px;
        top: 18px;
        left: 15px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../assets/icon_close.png');
      }
    }

    .summary {
      padding: 30px;
      text-align: center;
      h2 {
        font-weight: normal;
        font-size: 22px;
        line-height: 30px;
        color: #717171;
        text-align: center;
        margin: 15px 0;
      }
      p {
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #707781;
        margin: 0;
      }
      div {
        text-align: center;
        background: none transparent;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../assets/health_white.png');
        padding: 32px 0;
        font-weight: normal;
        font-size: 33px;
        line-height: 30px;
        letter-spacing: 0.07em;
      }
    }

    .overview {
      background-color: #F2F2F2;
      h2 {
        margin: 0;
        padding: 20px 20px 10px;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #717171;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        border-bottom: 1px solid #ddd;
        li {
          border-top: 1px solid #ddd;
          a {
            display: block;
            padding: 4px 40px 10px 20px;
            position: relative;
            background-position: right 15px center;
            background-repeat: no-repeat;
            background-image: url('../assets/icon_arrow-right.png');
          }
          strong {
            font-weight: normal;
            font-size: 13px;
            color: #707781;
            display: block;
          }
          .bar {
            height: 12px;
            .bar-value {
              text-align: right;
              font-size: 9px;
              color: white;
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
              padding-right: 4px;
            }
          }
        }
      }
    }

    .projection {
      padding: 20px 20px 10px;
      h2 {
        margin: 0;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #717171;
      }
      #health-projection-chart {
        .highcharts-credits {
          display: none;
        }
        .trend-data-label {
          border-radius: 50%;
          width: 32px;
          height: 32px;
          line-height: 28px;
          display: block;
          text-align: center;
          color: white;
          font-size: 10px;
          border: 2px solid white;

          position: relative;
          top: -18px;
        }
      }
    }

    .about {
      background-color: #F2F2F2;
      padding: 20px 20px 10px;
      h2 {
        margin: 0 0 25px;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #717171;
      }
      .score {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        div {
          font-weight: bold;
          font-size: 11px;
          letter-spacing: 0.07em;
          color: #929292;
          width: 130px;
        }
        p {
          font-weight: normal;
          font-size: 11px;
          line-height: 13px;
          color: #707781;
          border-left: 1px solid #ddd;
          margin: 0;
          padding-bottom: 20px;
          padding-left: 10px;
          max-width:300px
        }
      }
    }

  }
}

.route-project-health-score {
  .layout > .row {
    min-height: 100vh;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
    h1 {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.02em;
      color: #FFFFFF;
      display: inline-block;
      margin: 0;
      width: 100%;
      padding-right: 56px;
      text-align: center;
      padding-top: 18px;
    }
    .back {
      color: white;
      font-weight: normal;
      display: inline-block;
      margin-right: 13px;
      padding-left: 20px;
      line-height: 1;
      padding-top: 22px;
      background-position: left center;
      background-repeat: no-repeat;
      background-image: url('../assets/icon_back.png');
    }
  }
  section {
    background-color: white;
    min-height: calc(100vh - 60px);
    margin-left: -15px;
    margin-right: -15px;
    padding: 15px 15px 95px;

    .summary {
      margin: 5px 0 25px;
      text-align: center;
      h2 {
        font-weight: normal;
        font-size: 22px;
        line-height: 30px;
        color: #717171;
        text-transform: capitalize;
      }
      div {
        font-weight: normal;
        font-size: 33px;
        line-height: 30px;
        letter-spacing: 0.07em;
        &.green {
          color: #5AC53A;
        }
        &.light-green {
          color: #AADD69;
        }
        &.yellow {
          color: #EDD86D;
        }
        &.red {
          color: #ED6D6D;
        }
        &.black {
          color: #000;
        }
      }
    }

    .detail {
      background: #F2F2F2;
      border-radius: 16px;
      padding: 15px;
      margin-bottom: 10px;
      display: block;
      h3 {
        margin: 0 0 8px;
        font-weight: bold;
        font-size: 11px;
        line-height: 1;
        letter-spacing: 0.07em;
        color: #929292;
        text-transform: uppercase;
      }
      p {
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #717171;
        margin: 0;
      }
    }

    .trend {
      background: #F2F2F2;
      border-radius: 16px;
      padding: 15px;
      margin-bottom: 10px;
      display: block;
      h3 {
        margin: 0 0 8px;
        font-weight: bold;
        font-size: 11px;
        line-height: 1;
        letter-spacing: 0.07em;
        color: #929292;
        text-transform: uppercase;
      }
      p {
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #717171;
        margin: 0;
      }
      .chart {
        height: 100px;
      }

      #health-score-chart {
        .highcharts-credits {
          display: none;
        }
        .trend-data-label {
          border-radius: 50%;
          width: 32px;
          height: 32px;
          line-height: 28px;
          display: block;
          text-align: center;
          color: white;
          font-size: 10px;
          border: 2px solid #F2F2F2;

          position: relative;
          top: -18px;
        }
      }
    }

    .project-lead {
      background: #F2F2F2;
      border-radius: 16px;
      padding: 15px;
      margin-bottom: 10px;
      display: block;
      h3 {
        margin: 0 0 8px;
        font-weight: bold;
        font-size: 11px;
        line-height: 1;
        letter-spacing: 0.07em;
        color: #929292;
        text-transform: uppercase;
      }
      & > span {
        float: right;
        font-size: 13px;
        color: #707781;
        position: relative;
        top: -4px;
      }
      .actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        .btn {
          background: #7D89FC;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
          border-radius: 10px;
          text-align: center;
          color: white;
          font-weight: normal;
          font-size: 10px;
          line-height: 12px;
          flex-grow: 1;
          width: 30%;
          padding-top: 30px;
          background-position: center 8px;
          background-repeat: no-repeat;
          background-image: url('../assets/icon_envelope_white.png');
          &:nth-child(2) {
            margin-left: 13px;
            margin-right: 13px;
            background-image: url('../assets/icon_phone.png');
          }
          &:nth-child(3) {
            background-image: url('../assets/icon_chat.png');
          }
        }
      }
    }

  }
}

.route-project-users {
  .layout > .row {
    min-height: 100vh;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
    h1 {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.02em;
      color: #FFFFFF;
      display: inline-block;
      margin: 0;
      width: 100%;
      padding-right: 56px;
      text-align: center;
      padding-top: 18px;
    }
    .back {
      color: white;
      font-weight: normal;
      display: inline-block;
      margin-right: 13px;
      padding-left: 20px;
      line-height: 1;
      padding-top: 22px;
      background-position: left center;
      background-repeat: no-repeat;
      background-image: url('../assets/icon_back.png');
    }
  }
  section {
    background-color: white;
    min-height: calc(100vh - 60px);
    margin-left: -15px;
    margin-right: -15px;
    padding: 0 0 95px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 10px 15px;
        border-bottom: 1px solid #ddd;
        &:nth-child(1) span {
          background-color: #18A0FB;
        }
        &:nth-child(2) span {
          background-color: #7D89FC;
        }
        &:nth-child(3) span {
          background-color: #E8637B;
        }
        & > div {
          margin-left: 15px;
          font-size: 13px;
          color: #717171;
          line-height: 1;
          strong {
            display: block;
            margin-bottom: 3px;
          }
        }
        span {
          display: inline-block;
          height: 28px;
          width: 28px;
          text-align: center;
          border-radius: 50%;
          text-transform: uppercase;
          font-size: 10px;
          line-height: 28px;
          vertical-align: middle;
          background-color: #7B39F5;
          color: white;
        }
      }
    }
    .btn-primary {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      padding: 14px;
      color: #626DD8;
      background: #FFFFFF;
      border: 1px solid #626DD8;
      box-shadow: 0px 0px 10px #7D89FC;
      border-radius: 10px;
      margin: 15px;
      display: block;
      width: calc(100% - 30px);
      text-align: center;
    }
  }
}

.route-project-settings {
  .layout > .row {
    min-height: 100vh;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
    h1 {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.02em;
      color: #FFFFFF;
      display: inline-block;
      margin: 0;
      width: 100%;
      padding-right: 56px;
      text-align: center;
      padding-top: 18px;
    }
    .back {
      color: white;
      font-weight: normal;
      display: inline-block;
      margin-right: 13px;
      padding-left: 20px;
      line-height: 1;
      padding-top: 22px;
      background-position: left center;
      background-repeat: no-repeat;
      background-image: url('../assets/icon_back.png');
    }
  }
  section {
    background: white;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    min-height: calc(100vh - 60px);
    h2 {
      margin: 15px 0 20px;
      font-weight: bold;
      font-size: 18px;
    }
    h3 {
      font-weight: bold;
      font-size: 11px;
      line-height: 30px;
      letter-spacing: 0.07em;
      color: #909090;
      text-transform: uppercase;
    }
    .group {
      border-bottom: 1px solid #DDDDDD;
      margin: 0;
      padding: 30px 0;
      .date {
        font-weight: normal;
        font-size: 16px;
        padding: 14px;
        border: 1px solid #DDDDDD;
        background: #white;
        border-radius: 6px;
        color: #717171;
      }
      span {
        float: right;
        font-weight: normal;
        font-size: 16px;
        color: #626DD8;
        margin-top: 3px;
      }
    }
  }
}


// Dark theme
.dark-mode {
  &.route-project-details {
    .header {
      h1 {
        color: #FFFFFF;
      }
      .back {
        color: white;
      }
      .users {
        .user {
          border-color: #142776;
        }
        .add {
          border-color: #0D0E3F;
        }
      }
    }
    section {
      background: transparent;
      #summary-chart {
        background: #0D0E3F;
        .highcharts-tooltip {
          & > span {
            background-color: #0D0E3F;
          }
        }
        .date {
          color: white;
        }
      }
      .summary-legend {
        color: white;
      }
      .summary-units {
        background: #0D0E3F;
        border-color: #412899;
        .btn {
          color: #929292;
          &.active {
            background: #412899;
            color: white;
          }
        }
      }
      .summary-timeframe {
        background: #0D0E3F;
        border-color: #412899;
        .btn {
          color: #929292;
          &.active {
            background: #412899;
            color: white;
          }
        }
      }
      .bg {
        background: #25295B;
      }
      .budget {
        & > div {
          strong {
            color: white;
          }
          div {
            color: #D8D8D8;
          }
        }
      }
      .status {
        small {
          color: #D8D8D8;
        }
      }
      .timeline {
        strong {
          color: white;
        }
        .details {
          & > div {
            b {
              color: white;
            }
            span {
              color: #D8D8D8;
            }
          }
        }
      }
      .health {
        div {
          background-image: url('../assets/health_dark.png');
        }
      }
      .budget-projection {
        p {
          color: #D8D8D8;
        }
      }
      .project-lead {
        & > span {
          color: #D8D8D8;
        }
        .actions {
          .btn {
            background-color: #423AA5;
          }
        }
      }
      .details {
        h3 {
          color: white;
        }
        p {
          color: #D8D8D8;
        }
      }
    }
    .project-carousel {
      background: #0D0E3F;
      box-shadow: none;
      border-top-color: #616B85;
      & > div {
        & > a {
          color: white;
        }
      }
      ul {
        span {
          display: block;
          width: 8px;
          height: 8px;
          background-color: #A0A1A3;
          border-radius: 50%;
          &.active {
            background-color: white;
          }
        }
      }
    }
  }
  &.route-project-health {
    section {
      background: #0D0E3F;
      .header {
        h1 {
          color: white;
        }
      }
      .summary {
        h2 {
          color: white;
        }
        p {
          color: white;
        }
        div {
          background-image: url('../assets/health_dark-2.png');
        }
      }
      .overview {
        background-color: transparent;
        h2 {
          color: white;
        }
        ul {
          border-bottom-color: #717171;
          li {
            border-top-color: #717171;
            strong {
              color: white;
            }
          }
        }
      }
      .projection {
        h2 {
          color: white;
        }
        #health-projection-chart {
          .trend-data-label {
            border-color: #0d0e3f;
          }
        }
      }
      .about {
        background-color: transparent;
        h2 {
          color: white;
        }
        .score {
          p {
            color: white;
          }
        }
      }
    }
  }

  &.route-project-health-score {
    section {
      background-color: transparent;
      .summary {
        h2 {
          color: white;
        }
      }
      .detail {
        background: #25295B;
        p {
          color: white;
        }
      }
      .trend {
        background: #25295B;
        p {
          color: white;
        }
        #health-score-chart {
          .trend-data-label {
            border-color: #25295B;
          }
        }
      }
      .project-lead {
        background: #25295B;
        & > span {
          color: #D8D8D8;
        }
        .actions {
          .btn {
            background-color: #423AA5;
          }
        }
      }

    }
  }

  &.route-project-users {
    section {
      background-color: transparent;
      ul {
        li {
          border-bottom-color: #7D7D7D;
          & > div {
            color: #D8D8D8;
            strong {
              color: white;
            }
          }
        }
      }
      .btn-primary {
        background: transparent;
      }
    }
  }

  &.route-project-settings {
    section {
      background-color: transparent;
      h2 {
        color: white;
      }
      h3 {
        color: #D8D8D8;
      }
      .group {
        border-bottom-color: #7D7D7D;
        color: white;
        .date {
          color: white;
          border-color: #8269E3;
          background: #423AA5;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}
