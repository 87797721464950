.route-homepage {
  padding-top: 15px;
  padding-bottom: 85px;
  .row {
    min-height: 100vh;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
    .logo {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url('../assets/logo.png');
      margin: 0;
      width: 60px;
      height: 51px;
      white-space: nowrap;
      overflow: hidden;
      text-indent: -200%;
      display: inline-block;
      vertical-align: top;
    }
    h1 {
      color: white;
      font-size: 24px;
      line-height: 55px;
      font-weight: normal;
      letter-spacing: -0.02em;
      display: inline-block;
      vertical-align: top;
      margin: 0 0 0 5px;
    }
    a {
      color: white;
      font-weight: normal;
      font-size: 16px;
      display: inline-block;
      margin-top: 15px;
    }
  }
  .nav-tabs {
    border: none;
    margin: 0 0 38px;
    .nav-link {
      margin: 0 30px 0 0;
      border: none;
      background-color: transparent;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: rgba(255, 255, 255, 0.5);
      padding-left: 0;
      padding-right: 0;
      border-radius: none;
      &.active {
        color: white;
        border-bottom: 3px solid #818DFE;
      }
    }
  }
  .tab-content .projects {
    padding-bottom: 95px;
    .project {
      background: #FFFFFF;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      padding: 15px;
      display: block;
      div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      strong {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #717171;
        display: block;
        margin-bottom: 5px;
      }
      small {
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #717171;
        max-width: 225px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .health {
        border-radius: 10px;
        color: white;
        font-weight: bold;
        font-size: 11px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.07em;
        padding: 2px;
        min-width: 65px;
        height: 24px;
        &.red {
          background-color: #ED6D6D;
        }
        &.orange {
          background-color: #EDD86D;
        }
        &.green {
          background-color: #5AC53A;
        }
      }
      .trend {
        display: block;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.07em;
        color: #909090;
        margin-top: 2px;
        .up {
          display: inline-block;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-bottom: 7px solid #909090;
          margin-left: 4px;
        }
        .down {
          display: inline-block;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-top: 7px solid #909090;
          margin-left: 4px;
        }
        .neutral {
          background-color: #909090;
          display: inline-block;
          width: 15px;
          height: 2px;
          margin-left: 4px;
          position: relative;
          top: -2px;
        }
      }
      & + .project {
        margin-top: 15px;
      }
    }
  }
}

.route-projects-edit {
  padding-top: 15px;
  padding-bottom: 85px;
  .row {
    min-height: 100vh;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
    h1 {
      color: white;
      font-size: 24px;
      line-height: 55px;
      font-weight: normal;
      letter-spacing: -0.02em;
      display: inline-block;
      vertical-align: top;
      margin: 0;
    }
    a {
      color: white;
      font-weight: normal;
      font-size: 16px;
      display: inline-block;
      margin-top: 15px;
    }
  }
}

// Dark theme
.dark-mode {
  &.route-homepage {
    .projects .project {
      background: linear-gradient(90deg, #423AA5 2.19%, #3F1C92 100%);
      strong {
        color: white;
      }
      small {
        color: #D8D8D8;
      }
    }
  }
  &.route-projects-edit {
    .form-group-inset-label {
      .icon {
        border-right-color: #8269E3;
      }
      .form-label {
        color: #929292;
      }
      .form-control {
        background: #423AA5;
        color: white;
        border-color: #8269E3;
      }
    }
    .project-list .project-body {
      background: linear-gradient(90deg, #423AA5 2.19%, #3F1C92 100%);
      strong {
        color: white;
      }
      small {
        color: #D8D8D8;
      }
    }
  }
}
