.box {
  border: 1px solid theme-color("dark");
  background-color: white;
  margin-bottom: $grid-gutter-width;
  border-radius: $border-radius;
  .box-body {
    padding: $grid-gutter-width / 3;
    @include media-breakpoint-up(md) {
      padding: $grid-gutter-width / 2;
    }
  }
  .box-header {
    padding: $grid-gutter-width / 4 $grid-gutter-width / 3;
    border-bottom: 1px solid theme-color("dark");
    @include media-breakpoint-up(md) {
      padding: $grid-gutter-width / 3 $grid-gutter-width / 2;
    }
    h3 {
      margin: 0;
      text-transform: uppercase;
      display: inline-block;
      font-size: 0.9em;
    }
  }
  .box-empty {
    text-align: center;
    padding: $grid-gutter-width;
  }
}
