.form-container {
  position: relative;
}

.has-error {
  .form-error {
  //  position: absolute;
  //  background-color: #ED6D6D;
    color: #ED6D6D;
    border-radius: 2px;
		padding: 2px 0 2px 15px;
		margin: 6px 0 0 0;
    font-size: 11px;
		bottom: 0;
		left: 0;
    line-height: 1;

    background-image: url('../assets/icon_error.png');
    background-position: left center;
    background-repeat: no-repeat;
    /*
    &:after {
			top: 100%;
			right: 5px;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: transparent;
			border-top-color: #ED6D6D;
			border-width: 5px;
		}
    */
  }
}

.form-error-static {
  position: absolute;
  background-color: #ED6D6D;
  color: white;
  border-radius: 2px;
  padding: 4px 6px;
  margin: 0 0 6px;
  font-size: 12px;
  line-height: 1;
  display: inline-block;
}
