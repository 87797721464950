.layout {
  position: relative;
 a,
 a:hover {
   text-decoration: none;
 }
 .notification {
   background-color: rgba(white, 0.4);
   border-radius: 10px;
   padding: 15px;
   position: absolute;
   top: 10px;
   left: 10px;
   right: 10px;
   color: #111;
   display: block;
   z-index: 1;
   transition: all .3s;
   transform: translate3d(0, -200%, 0);
   box-shadow: 0 3px 6px rgba(0,0,0,0.25);
   span {
     float: right;
     font-size: 10px;
     opacity: 0.5;
   }
   strong {
     display: block;
     font-size: 13px;
   }
   .app {
     text-transform: uppercase;
     margin-bottom: 10px;
     background-position: left center;
     background-repeat: no-repeat;
     background-size: 24px auto;
     background-image: url('../assets/ios.png');
     padding-left: 30px;
     color: rgba(#111, 0.5);
   }
   .subject {
     font-weight: bold;
     font-size: 13px;
   }
   .preview {
     font-size: 13px;

   }
   &.show {
     transform: translate3d(0, 0, 0);
   }
   &.hide {
     transform: translate3d(0, -200%, 0);
   }
 }
}

.dark-mode {
  background: linear-gradient(149.33deg, #1B43B3 6.39%, #0D0E3F 21.46%, #0D0E3F 65.71%);
  .layout {

  }
}

.light-mode {

  background: linear-gradient(159.85deg, #7B39F5 7.17%, #40C3FD 105.92%);
  .layout {

  }
}

.navbar.fixed-bottom + .layout {
  margin-bottom: $nav-link-height;
  padding-bottom: $grid-gutter-width;
}
