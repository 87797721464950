.ReactTable {
  font-size: 0.9em;
  .rt-table {
    .rt-thead {
      &.-header {
        box-shadow: none;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        background-color: #F7F9FB;
      }
      .rt-th,
      .rt-td {
        &.-sort-asc {
          box-shadow: inset 0 3px 0 0 rgba(theme-color("primary"), 0.6);
        }
        &.-sort-desc {
          box-shadow: inset 0 -3px 0 0 rgba(theme-color("primary"), 0.6);
        }
      }
      .rt-th {
        font-weight: bold;
      }
    }
    .rt-tbody {
      .rt-tr {
        &.-even {
          background-color: #F4F6F9;
        }
      }
    }
  }
}
.table-actions {
  padding-bottom: $grid-gutter-width / 3;
  @include media-breakpoint-up(md) {
    padding-bottom: $grid-gutter-width / 2;
  }
  .table-search {
    max-width: 260px;
  }
}
