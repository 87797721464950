.route-login,
.route-reset-password,
.route-update-password,
.route-complete-account,
.route-sign-up,
.route-sign-up-success,
.route-reset-password-success {

  .row {
    min-height: 100vh;
  }
  .logo {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../assets/logo.png');
    margin: 40px auto 0;
    width: 120px;
    height: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-indent: -200%;
  }
  h1 {
    text-align: center;
    font-size: 28px;
    line-height: 34px;
    font-weight: normal;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    margin: 5px auto $grid-gutter-width * 1.5;
  }
  h2 {
    font-weight: normal;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.02em;
    color: #707070;
    text-align: center;
    margin: $grid-gutter-width / 2 0 $grid-gutter-width / 1.2;
  }
  form {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: $grid-gutter-width / 2;
    max-width: 305px;
    margin: $grid-gutter-width / 2 auto;
    .link {
      display: inline-block;
      margin-bottom: $grid-gutter-width / 2;
      color: #6F6DFF;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
    }
    .help {
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #717171;
    }
    .btn {
      width: 100%;
      display: block;
      background: #626DD8;
      box-shadow: 0px 0px 10px #7D89FC;
      border-radius: 10px;
      color: #FFFFFF;
      font-size: 16px;
      padding: 0.7rem 0.75rem;
      margin: $grid-gutter-width 0 $grid-gutter-width / 2;
    }
    & + p {
      text-align: center;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #DDDDDD;
      margin-top: $grid-gutter-width * 2.8;
      a {
        color: white;
        text-decoration: underline;
      }
    }
  }
  .terms {
    margin: $grid-gutter-width 0;
    padding: 0 $grid-gutter-width;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #DDDDDD;
  }
  .password-req {
    margin-top: -10px;
    margin-bottom: 15px;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: #717171;
    div {
      background-position: left center;
      background-repeat: no-repeat;
      background-image: url('../assets/icon_cross_small.png');
      padding-left: 15px;
      &.valid {
        background-image: url('../assets/icon_check_small.png');
        color: #5AC53A;
      }
      .red {
        color: #ED6D6D;
      }
      .orange {
        color: #EDD86D;
      }
    }
  }
}

.route-sign-up-success,
.route-reset-password-success {
  position: relative;
  h2 {
    color: white;
    text-align: center;
    margin: 30px auto 45px;
    font-weight: normal;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.02em;
  }
  p {
    color: white;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    max-width: 285px;
    margin: 0 auto;
  }
  .icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../assets/crow.png');
    margin: 200px auto 0;
    width: 37px;
    height: 38px;
  }
  .btn {
    margin: $grid-gutter-width * 2 auto 0;
    background-color: transparent;
    border-color: #fff;
    color: #fff;
    border-radius: 10px;
    color: #FFFFFF;
    font-size: 16px;
    padding: 0.7rem 0.75rem;
    display: block;
    width: 72.8%;
    min-width: 275px;
  }
  .continue {
    position: absolute;
    right: 30px;
    bottom: 30px;
    color: #FFFFFF;
    font-size: 16px;
    opacity: 0.45;
  }
}
