.project-navigation {
  margin-bottom: 30px;
  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden;
  padding-bottom: 5px;
  .slick-slider {
    background: #F2F2F2;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: -7px;
      left: 50%;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 7px solid #F2F2F2;
      transform: translate3d(-50%, 0, 0);
    }
  }
  .slick-track {
    display: flex;
    align-items: stretch;
  }
  .slick-slide {
    display: flex;
    align-self: stretch;
    height: unset;
    .disabled,
    a {
      display: flex;
      align-self: stretch;
      width: 100%;
      padding: 15px 10px 15px 20px;
      font-size: 11px;
      text-transform: uppercase;
      span {
        display: inline-block;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        margin-left: 4px;
        background-color: red;
        position: relative;
        top: 5px;
      }
    }
    .disabled {
      opacity: .3;
      span {
        background-color: gray;
      }
    }
  }

  .timeline {
    position: relative;
    padding: 0 15px;
    .highcharts-container  {
      opacity: .5;
      .highcharts-credits {
        display: none;
      }
    }
    .overlay {
      position: absolute;
      top: -100px;
      left: 50% !important;
      transform: translate3d(-50%, 0, 0);
      background: rgba(#25295B, 0.6);
      border-radius: 16px;
      text-align: center;
      width: 70px;
      padding: 11px 0;
      strong {
        display: block;
        letter-spacing: -0.05em;
        font-weight: normal;
        font-size: 33px;
        line-height: 30px;
      }
      span {
        margin-top: 7px;
        display: block;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        color: white;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: -7px;
        left: 50%;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid rgba(#25295B, 0.6);
        transform: translate3d(-50%, 0, 0);
      }
    }
  }

  .slider {
    margin: 5px 4px 15px;
    .horizontal-slider {
      width: 100%;
      position: relative;
      .timeline-thumb {
        font-size: 0.7em;
        text-align: center;
        color: black;
        padding: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid #000;
        background-color: #fff;
        transform: translate3d(0, -8px, 0);

        line-height: 16px;
        &.active {
          //background-color: grey;
        }
        &:focus,
        &:active,
        &:active:focus {
          box-shadow: none;
          outline: none;
        }
      }
      .timeline-track {
        left: 0;
        right: 0;
        position: absolute;
        background: #ddd;
        height: 4px;
        border-radius: 10px;
      }

      .timeline-mark {
        width: 8px;
        height: 8px;
        border: 2px solid #000;
        background-color: #fff;
        cursor: pointer;
        border-radius: 50%;
        margin: 0 calc(10px - 4px);
        bottom: -6px;
      }
    }
  }
}

// Dark theme
.dark-mode {
  .project-navigation {
    .slick-slider {
      background: linear-gradient(90deg, #423AA5 2.19%, #3F1C92 100%);
      &:after {
        border-top: 7px solid #412b9c;
      }
    }
    .slick-slide {
      .disabled,
      a {
        color: white;
      }
    }
  }
}
