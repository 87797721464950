.form-group {
  &.form-group-inset-label {
    position: relative;
    .form-container {
      position: relative;
    }
    .react-select__control,
    .form-control {
      background-color: #fff;
      border-color: #ddd;
      border-radius: 6px;
      padding-left: 60px;
      height: 48px;
    }
    .react-select--is-disabled .react-select__control {
      background: white;
    }
    .form-label {
      position: absolute;
      top: 13px;
      left: 60px;
      z-index: 1;
      transition: all .3s;
      font-size: 0.9rem;
      color: #717171;
      margin: 0;
    }
    .icon {
      border-right: 1px solid #ddd;
      width: 48px;
      height: 48px;
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      &.envelope {
        background-image: url('../assets/icon_envelope.png');
      }
      &.key {
        background-image: url('../assets/icon_key.png');
      }
      &.user {
        background-image: url('../assets/icon_profile.png');
      }
      &.search {
        background-image: url('../assets/icon_search.png');
      }
    }
    &.has-error {
      .react-select__control,
      .form-control,
      .icon {
        border-color: #ED6D6D;
      }
      .form-label {
        color: #ED6D6D;
      }
    }
    &.has-value {
      .form-control {
        padding-top: 15px;
      }
      .form-label {
        top: 5px;
        font-size: 11px;
        /*
        &:before {
          content: '';
          position: absolute;
          left: -4px;
          right: -4px;
          top: 0;
          height: 10px;
          background-color: white;
          z-index: -1;
        }
        */
      }
    }
    .form-text {
      text-align: right;
      opacity: 0.5;
    }
  }
}
