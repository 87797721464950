// @import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

@import 'settings.scss';

@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/regular.scss';

@import 'components/box';
@import 'components/form';
@import 'components/form-datepicker';
@import 'components/form-error';
@import 'components/form-file-upload';
@import 'components/form-react-select';
@import 'components/layout';
@import 'components/loading';
@import 'components/navbar';
@import 'components/notifications';
@import 'components/react-table';
@import 'components/scroll-box';
@import 'components/toggle';
@import 'components/modal-confirmation';
@import 'components/project-navigation';
@import 'components/react-slick';

@import 'routes/login';
@import 'routes/homepage';
@import 'routes/projects';
@import 'routes/projects-t2';
@import 'routes/profile';
@import 'routes/search';
@import 'routes/notifications';
@import 'routes/admin';
