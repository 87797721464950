.navbar {
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #ddd;
  height: 70px;
  .navbar-nav {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .nav-link {
    flex-grow: 1;
    text-align: center;
    color: #717171;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    padding-top: 25px;
    padding-bottom: 0;
    background-position: center top;
    background-repeat: no-repeat;
    position: relative;
    &.active {
      color: #7D89FC !important;
    }
    &.projects {
      background-image: url('../assets/icon_projects.png');
      &.active {
        background-image: url('../assets/icon_projects_active.png');
      }
    }
    &.profile {
      background-image: url('../assets/icon_profile.png');
      &.active {
        background-image: url('../assets/icon_profile_active.png');
      }
    }
    &.search {
      background-image: url('../assets/icon_search.png');
      &.active {
        background-image: url('../assets/icon_search_active.png');
      }
    }
    &.notifications {
      background-image: url('../assets/icon_notifications.png');
      &.active {
        background-image: url('../assets/icon_notifications_active.png');
      }
    }
    &.admin {
      background-image: url('../assets/icon_admin.png');
      &.active {
        background-image: url('../assets/icon_admin_active.png');
      }
    }
    .badge {
      background-color: #ED6D6D;
      border-radius: 50%;
      position: absolute;
      top: -5px;
      right: 35%;
      color: white;
      width: 18px;
      height: 18px;
      font-size: 13px;
      line-height: 18px;
      color: #FFFFFF;
      padding: 0;
      font-weight: normal;
    }
  }
}

// Dark theme
.dark-mode {
  .navbar {
    background-color: #0D0E3F;
    border-top-color: #2E323E;
    box-shadow: none;
    .nav-link {
      color: #929292;
      &.active {
        color: #7D89FC !important;
      }
    }
  }
}
