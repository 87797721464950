.route-profile {
  .row {
    min-height: 100vh;
  }
  section {
    background-color: white;
    min-height: calc(100vh - 85px);
    margin-top: 85px;
    margin-left: -15px;
    margin-right: -15px;
    padding: 15px 15px 95px;
    h2 {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: #717171;
    }
    h3 {
      font-weight: bold;
      font-size: 11px;
      line-height: 30px;
      letter-spacing: 0.07em;
      color: #909090;
      text-transform: uppercase;
    }
    .group {
      span {
        float: right;
        font-weight: normal;
        font-size: 16px;
        color: #626DD8;
        margin-top: 3px;
      }
    }
    .group + .group {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid #DDDDDD;
    }
    .btn {
      background: #FFFFFF;
      border: 1px solid #626DD8;
      box-shadow: 0px 0px 10px #7D89FC;
      border-radius: 10px;
      display: block;
      width: 100%;
      padding: 15px;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #626DD8;
    }
    .theme-switch {
      background: #DDDDDD;
      border-radius: 6px;
      border: 2px solid #DDDDDD;
      padding: 2px;
      display: flex;
      flex-direction: row;
      margin-bottom: 40px;
      .btn {
        box-shadow: none;
        border-radius: 5px;
        border: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        padding: 8px;
        color: #717171;
        &.active {
          background: #7D89FC;
          color: white;
          &:focus,
          &:active,
          &:active:focus {
            outline: none;
            box-shadow: none;
          }
        }
        &:focus,
        &:active,
        &:active:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
  .dropdown-toggle {
    box-shadow: none !important;
    background: none transparent !important;
    border: none !important;
    margin: -75px auto 10px;
    position: relative;
    padding: 0 !important;
    width: 126px !important;
    &:after {
      display: none;
    }
    &:focus {
      box-shadow: none;
    }
  }
  .dropdown-menu {
    width: 195px;
    border: none;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 0;
    left: -30px !important;
    &.show {
      margin-top: -25px;
    }
    .dropdown-item {
      font-weight: normal;
      font-size: 16px;
      line-height: 39px;
      color: #000000;
      padding: 0 15px;
      background-position: 165px center;
      background-repeat: no-repeat;
      background-size: 20px;
      & + .dropdown-item {
        border-top: 1px solid #DDDDDD;
      }
      &:nth-child(1) {
        background-image: url('../assets/icon_picture.png');
      }
      &:nth-child(2) {
        background-image: url('../assets/icon_camera.png');
      }
      &:nth-child(3) {
        background-image: url('../assets/icon_folder.png');
      }
    }
  }
  .avatar {
    width: 126px;
    height: 126px;
    border-radius: 50%;
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../assets/icon_avatar.png');
    &.uploaded {
      border: 8px solid white;
      background-size: cover;
      background-image: url('../assets/avatar.png');
    }
    & > span {
      display: block;
      position: absolute;
      right: 10px;
      bottom: 10px;
      border-radius: 50%;
      width: 26px;
      height: 26px;
      line-height: 24px;
      background-color: #626DD8;
      text-align: center;
      color: white;
      font-size: 22px;
      text-indent: 1px;
    }
  }
}

// Dark theme
.dark-mode {
  &.route-profile {
    section {
      background: transparent;
    }
    .avatar {
      background-color: #412D9C;
      &.uploaded {
        border-color: #412D9C;
      }
    }
    h2 {
      color: #ffffff;
    }
    h3 {
      color: #D8D8D8;
    }
    .group {
      color: white;
      & + .group {
        border-top-color: #7D7D7D;
      }
    }
    .btn {
      background-color: transparent;
    }
    .theme-switch {
      background: transparent;
      border-color: #412899;
      .btn {
        color: #717171;
        &.active {
          background: #412899;
          color: white;
        }
      }
    }
  }
}
