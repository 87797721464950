.toggle-group {
  display: inline-block;
  .toggle {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 24px;
    margin: 0;
    vertical-align: middle;
  }
  input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    &:checked {
      & + .toggle-slider {
        background-color: theme-color("success");
        &:before {
          transform: translateX(100%);
        }
      }
    }
    &:focus + .toggle-slider {
      box-shadow: 0 0 1px theme-color("success");
    }
  }
  .toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: theme-color("secondary");
    transition: .4s;
    border-radius: 2em;
    &:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 3px;
      bottom: 2px;
      background-color: white;
      transition: .4s;
      border-radius: 50%;
    }
  }
  .toggle-label {
    margin-left: 10px;
    vertical-align: middle;
    display: inline-block;
  }
  &.sm {
    .toggle {
      width: 34px;
      height: 18px;
    }
    .toggle-slider {
      &:before {
        height: 14px;
        width: 14px;
        left: 3px;
        border-radius: 50%;
      }
    }
  }
}
