.loading {
  text-align: center;
  svg {
    width: 100px;
    height: 100px;
    margin: 0 auto;
  }
  &.full-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh + 50px);
    & > div {
      padding: $grid-gutter-width;
    }
  }
}
