.route-admin-dashboard {
  padding-top: 15px;
  padding-bottom: 95px;
  .layout > .row {
    min-height: 100vh;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
    .logo {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url('../assets/logo.png');
      margin: 0;
      width: 60px;
      height: 51px;
      white-space: nowrap;
      overflow: hidden;
      text-indent: -200%;
      display: inline-block;
      vertical-align: top;
    }
    h1 {
      color: white;
      font-size: 24px;
      line-height: 55px;
      font-weight: normal;
      letter-spacing: -0.02em;
      display: inline-block;
      vertical-align: top;
      margin: 0 0 0 5px;
    }
  }
  section {
    margin-left: -15px;
    margin-right: -15px;
    padding: 15px 15px 95px;
    & > .row {
      margin-left: -5px;
      margin-right: -5px;
      & > .col {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
  .admin-link {
    display: block;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    background: #F2F2F2;
    margin-bottom: 10px;
    padding: 100px 0 45px;
    color: #717171;
    background-position: center 50px;
    background-repeat: no-repeat;
    &.users {
      background-size: 39px;
      background-image: url('../assets/icon_admin_avatar.png');
    }
    &.services {
      background-image: url('../assets/icon_admin_services.png');
    }
    &.logs {
      background-image: url('../assets/icon_admin_logs.png');
    }
    &.security {
      background-image: url('../assets/icon_admin_security.png');
    }
    &.premissions {
      background-image: url('../assets/icon_admin_premissions.png');
    }
    &.billing {
      background-image: url('../assets/icon_admin_billing.png');
    }
  }
}

.route-admin-users,
.route-admin-services,
.route-admin-security,
.route-admin-premissions,
.route-admin-logs,
.route-admin-billing {
  .layout > .row {
    min-height: 100vh;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
    h1 {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.02em;
      color: #FFFFFF;
      display: inline-block;
      margin: 0;
      width: 100%;
      padding-right: 56px;
      text-align: center;
      padding-top: 18px;
    }
    .back {
      color: white;
      font-weight: normal;
      display: inline-block;
      margin-right: 13px;
      padding-left: 20px;
      line-height: 1;
      padding-top: 22px;
      background-position: left center;
      background-repeat: no-repeat;
      background-image: url('../assets/icon_back.png');
    }

    .add {
      margin-top: 15px;
      display: block;
      span {
        display: inline-block;
        height: 34px;
        width: 34px;
        text-align: center;
        border-radius: 50%;
        background-color: #E9ECEE;
        color: #717171;
        line-height: 26px;
        font-size: 20px;
        border: 3px solid #7b3bf5;
        vertical-align: middle;
        position: relative;
        z-index: 1;
      }
    }
  }
  section {
    background-color: white;
    min-height: calc(100vh - 70px);
    padding: 0 0 95px;
  }
}

.route-admin-users {
  section {
    margin-left: -15px;
    margin-right: -15px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      border-top: 1px solid #ddd;
      li {
        border-bottom: 1px solid #ddd;
        &:nth-child(1) span {
          background-color: #18A0FB;
        }
        &:nth-child(2) span {
          background-color: #7D89FC;
        }
        &:nth-child(3) span {
          background-color: #E8637B;
        }
        a {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          padding: 10px 40px 10px 15px;
          background-position: right 15px center;
          background-repeat: no-repeat;
          background-image: url('../assets/icon_arrow-right.png');
          color: #717171;
          font-size: 13px;
          line-height: 40px;
          & > div {
            margin-left: 15px;
            font-size: 13px;
            color: #717171;
            line-height: 1;
            strong {
              display: block;
              margin-bottom: 3px;
            }
          }
          .role {
            margin-left: auto;
            font-size: 13px;
            border-radius: 10px;
            border: 1px solid;
            text-transform: capitalize;
            width: 60px;
            text-align: center;
            padding-top: 8px;
            &.viewer {
              color: #18A0FB;
              border-color: #18A0FB;

            }
            &.admin {
              color: #5AC53A;
              border-color: #5AC53A;

            }
            &.editor {
              color: #7B39F5;
              border-color: #7B39F5;
            }
          }
        }
        span {
          display: inline-block;
          height: 28px;
          width: 28px;
          text-align: center;
          border-radius: 50%;
          text-transform: uppercase;
          font-size: 10px;
          line-height: 28px;
          vertical-align: middle;
          background-color: #7B39F5;
          color: white;
        }
      }
    }

    .user-detail {
      position: relative;
      padding-left: 100px;
      margin-bottom: 20px;
      padding-top: 20px;
      strong {
        color: #717171;
        font-size: 13px;
      }
      div div {
        color: #717171;
        font-size: 13px;
        margin-bottom: 5px;
      }
      span {
        border: 2px solid #0D0E3F;
        display: inline-block;
        height: 44px;
        width: 44px;
        text-align: center;
        border-radius: 50%;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 40px;
        vertical-align: middle;
        background-color: #E8637B;
        color: white;
        position: absolute;
        left: 30px;
        top: 25px;
      }
      .role {
        font-size: 13px;
        border-radius: 10px;
        border: 1px solid;
        text-transform: capitalize;
        width: 60px;
        text-align: center;
        padding: 2px;
        &.viewer {
          color: #18A0FB;
          border-color: #18A0FB;

        }
        &.admin {
          color: #5AC53A;
          border-color: #5AC53A;

        }
        &.editor {
          color: #7B39F5;
          border-color: #7B39F5;
        }
      }
    }

    .projects {
      margin-top: 30px;
      padding: 0 15px;
      h3 {
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.02em;
        color: #717171;
        margin-bottom: 15px;
      }
      .project {
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        padding: 15px;
        display: block;
        div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        strong {
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          color: #717171;
          display: block;
          margin-bottom: 5px;
        }
        small {
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          color: #717171;
          max-width: 225px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .health {
          border-radius: 10px;
          color: white;
          font-weight: bold;
          font-size: 11px;
          line-height: 20px;
          text-align: center;
          letter-spacing: 0.07em;
          padding: 2px;
          min-width: 65px;
          height: 24px;
          &.red {
            background-color: #ED6D6D;
          }
          &.orange {
            background-color: #EDD86D;
          }
          &.green {
            background-color: #5AC53A;
          }
        }
        .trend {
          display: block;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 11px;
          line-height: 13px;
          letter-spacing: 0.07em;
          color: #909090;
          margin-top: 2px;
          .up {
            display: inline-block;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid #909090;
            margin-left: 4px;
          }
          .down {
            display: inline-block;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 7px solid #909090;
            margin-left: 4px;
          }
          .neutral {
            background-color: #909090;
            display: inline-block;
            width: 15px;
            height: 2px;
            margin-left: 4px;
            position: relative;
            top: -2px;
          }
        }
        & + .project {
          margin-top: 15px;
        }
      }
    }

    .form {
      margin: 0 30px;
    }

    .toggle {
      float: right;
      display: inline-block;
      width: 42px;
      height: 42px;
      border-radius: 6px;
      border: 1px solid #DDDDDD;
      margin-top: -8px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
      background-color: #F2F2F2;
      &.active {
        background-position: center;
        background-color: #423AA5;
        background-repeat: no-repeat;
        background-image: url('../assets/icon_toggle_active.png');
      }
    }

    .control-label {
      font-weight: normal;
      font-size: 13px;
      color: #717171;
      margin-bottom: 20px;
    }

    .form-control {
      background: white;
      border: 1px solid #DDDDDD;
      margin-bottom: 25px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      color: #717171;
      height: 48px;
      margin-bottom: 30px;
    }

    .btn {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      width: 100%;
      padding: 13px;
      background: #626DD8;
      box-shadow: 0px 0px 10px #7D89FC;
      border-radius: 10px;
      color: white;
      margin-top: 30px;
    }

    .success {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      margin-top: 60px;
      p {
        margin-bottom: 30px;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        &:first-child {
          background-position: center top;
          background-repeat: no-repeat;
          background-image: url('../assets/icon_toggle_active.png');
          padding-top: 30px;
        }
      }
      a {
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        border: 1px solid #8269E3;
        width: 100%;
        padding: 13px;
        background: transparent;
        box-shadow: none;
        border-radius: 10px;
        color: #8269E3;
        width: 80%;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }

  }
}

.route-admin-security {
  section {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    h3 {
      font-weight: bold;
      font-size: 11px;
      line-height: 30px;
      letter-spacing: 0.07em;
      color: #909090;
      text-transform: uppercase;
    }
    .group {
      border-bottom: 1px solid #DDDDDD;
      margin: 0;
      padding: 30px 0;
      span {
        float: right;
        font-weight: normal;
        font-size: 16px;
        color: #626DD8;
        margin-top: 3px;
      }
      .toggle {
        float: right;
        display: inline-block;
        width: 42px;
        height: 42px;
        border-radius: 6px;
        border: 1px solid #DDDDDD;
        background-color: #F2F2F2;
        margin-top: -8px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
        &.active {
          background-position: center;
          background-repeat: no-repeat;
          background-image: url('../assets/icon_toggle_active.png');
          background-color: #423AA5;
        }
      }
    }
  }
}

.route-admin-premissions {
  section {
    background-color: transparent;
    min-height: 0;
    padding: 0;
    h3 {
      font-weight: bold;
      font-size: 11px;
      line-height: 30px;
      letter-spacing: 0.07em;
      color: #909090;
      text-transform: uppercase;
    }
    .nav-tabs {
      border: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 15px;
      .nav-item {
        padding: 0;
        border-radius: 0;
        border: none;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.5);
        &.active {
          color: white;
          background: none transparent;
          border-bottom: 3px solid #818DFE;
        }
      }
    }
    .tab-content {
      background-color: white;
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 15px;
      padding-right: 15px;
      min-height: calc(100vh - 170px);
    }
    .group {
      border-bottom: 1px solid #DDDDDD;
      margin: 0;
      padding: 30px 0;
      span {
        float: right;
        font-weight: normal;
        font-size: 16px;
        color: #626DD8;
        margin-top: 3px;
      }
      .toggle {
        float: right;
        display: inline-block;
        width: 42px;
        height: 42px;
        border-radius: 6px;
        border: 1px solid #DDDDDD;
        margin-top: -8px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
        background-color: #F2F2F2;
        &.active {
          background-position: center;
          background-repeat: no-repeat;
          background-image: url('../assets/icon_toggle_active.png');
          background-color: #423AA5;
        }
      }
    }
  }
}

.route-admin-logs {
  section {
    background-color: transparent;
    min-height: calc(100vh - 60px);
    padding: 0;
    .logs-list {
      background-color: white;
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 15px;
      padding-right: 15px;
      min-height: calc(100vh - 190px);
      .log {
        padding: 25px 15px;
        border-bottom: 1px solid #ddd;
        &:nth-child(1) div span {
          background-color: #18A0FB;
        }
        &:nth-child(2) div span {
          background-color: #7D89FC;
        }
        &:nth-child(3) div span {
          background-color: #E8637B;
        }
        & > strong {
          font-size: 13px;
          display: block;
          margin-bottom: 15px;
        }
        & > div {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          & > div {
            margin-left: 15px;
            font-size: 13px;
            color: #717171;
            line-height: 1;
            strong {
              display: block;
              margin-bottom: 3px;
            }
          }
          span {
            display: inline-block;
            height: 28px;
            width: 28px;
            text-align: center;
            border-radius: 50%;
            text-transform: uppercase;
            font-size: 10px;
            line-height: 28px;
            vertical-align: middle;
            background-color: #7B39F5;
            color: white;
          }
        }
      }
    }
  }
}

.route-admin-services {
  section {
    background-color: transparent;
    min-height: 0;
    padding: 0;
    h3 {
      font-weight: normal;
      font-size: 18px;
      letter-spacing: -0.02em;
      color: #909090;
      padding-top: 40px;
    }
    .nav-tabs {
      border: none;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 15px;
      .nav-item {
        padding: 0;
        border-radius: 0;
        border: none;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.5);
        &.active {
          color: white;
          background: none transparent;
          border-bottom: 3px solid #818DFE;
        }
        & + .nav-item {
          margin-left: 30px;
        }
      }
    }
    .tab-content {
      background-color: white;
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 15px;
      padding-right: 15px;
      min-height: calc(100vh - 170px);
    }
    .app {
      background: #FFFFFF;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      border-radius: 16px;
      width: 110px;
      text-align: center;
      height: 110px;
      background-position: center;
      background-repeat: no-repeat;
      &.asana {
        background-image: url('../assets/logo_asana.png');
      }
      &.airtable {
        background-image: url('../assets/logo_airtable.png');
      }
      &.basecamp {
        background-image: url('../assets/logo_basecamp.png');
      }
      &.microsoft {
        background-image: url('../assets/logo_microsoft.png');
      }
      &.jira {
        background-image: url('../assets/logo_jira.png');
      }
      &.slack {
        background-image: url('../assets/logo_slack.png');
      }
      &.axosoft {
        background-image: url('../assets/logo_axosoft.png');
      }
      &.monday {
        background-image: url('../assets/logo_monday.png');
      }
      &.wrike {
        background-image: url('../assets/logo_wrike.png');
      }
    }
    .connected {
      padding: 20px 0 25px 125px;
      border-bottom: 1px solid rgba(black, 0.1);
      position: relative;
      .app {
        position: absolute;
        top: 15px;
        left: 0;
        width: 100px;
        height: 100px;
      }
      .edit {
        float: right;
        font-weight: bold;
        font-size: 16px;
        color: #7D89FC;
      }
      & > strong {
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.02em;
        color: #717171;
        display: block;
        margin-bottom: 10px;
        & + div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          div {
            width: 50%;
            span {
              font-weight: normal;
              font-size: 13px;
              line-height: 13px;
              color: #707781;
              &.con {
                border: 1px solid #5AC53A;
                border-radius: 10px;
                font-weight: normal;
                font-size: 13px;
                line-height: 16px;
                color: #5AC53A;
                padding: 3px 7px;
              }
              &.discon {
                border: 1px solid #DF5353;
                border-radius: 10px;
                font-weight: normal;
                font-size: 13px;
                line-height: 16px;
                color: #DF5353;
                padding: 3px 7px;
              }
              &.incomplete {
                border: 1px solid #717171;
                border-radius: 10px;
                font-weight: normal;
                font-size: 13px;
                line-height: 16px;
                color: #717171;
                padding: 3px 7px;
              }
            }
          }
        }
      }
      b {
        display: block;
        font-weight: bold;
        font-size: 11px;
        line-height: 30px;
        letter-spacing: 0.07em;
        color: #929292;
        text-transform: uppercase;
      }
    }
    .apps {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 -4px;
      .app {
        width: calc(100% / 3 - 8px);
        margin: 4px;
      }
    }
    .is-edit {
      background: #F2F2F2;
      border-radius: 16px;
      padding: 30px;
      position: relative;
      top: 15px;
      .cancel {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #7D89FC;
      }
      .app {
        margin: 0 auto 25px;
      }
      .form-control {
        background: white;
        border: 1px solid #DDDDDD;
        margin-bottom: 25px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        color: #717171;
        height: 48px;
      }
      .actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .btn {
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          width: 100%;
          padding: 13px;
          &.btn-delete {
            color: #ED6D6D;
          }
          &.btn-update {
            background: #626DD8;
            box-shadow: 0px 0px 10px #7D89FC;
            border-radius: 10px;
            color: white;
          }
        }
      }
    }
  }
}

.route-admin-billing {
  section {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}


// Dark theme
.dark-mode {
  &.route-admin-dashboard {
    .admin-link {
      color: white;
      background-color: #423AA5;
      &.users {
        background-image: url('../assets/icon_admin_avatar-white.png');
      }
      &.services {
        background-image: url('../assets/icon_admin_services-white.png');
      }
      &.logs {
        background-image: url('../assets/icon_admin_logs-white.png');
      }
      &.security {
        background-image: url('../assets/icon_admin_security-white.png');
      }
      &.premissions {
        background-image: url('../assets/icon_admin_premissions-white.png');
      }
      &.billing {
        background-image: url('../assets/icon_admin_billing-white.png');
      }
    }
  }
  &.route-admin-users,
  &.route-admin-services,
  &.route-admin-security,
  &.route-admin-premissions,
  &.route-admin-logs,
  &.route-admin-billing {
    section {
      background-color: transparent;
      margin-left: 0;
      margin-right: 0;
    }
  }
  &.route-admin-users {
    .header .add span {
      border-color: #0D0E3F;
    }
    .form-group-inset-label {
      .icon {
        border-right-color: #8269E3;
      }
      .form-label {
        color: #929292;
      }
      .form-control {
        background: #423AA5;
        color: white;
        border-color: #8269E3;
      }
    }
    section {
      margin-left: -15px;
      margin-right: -15px;
      ul {
        border-top-color: #7D7D7D;
        li {
          border-bottom-color: #7D7D7D;
          a {
            color: white;
            & > div {
              color: #D8D8D8;
              strong {
                color: white;
              }
            }
          }
        }
      }
      .user-detail {
        strong {
          color: white;
        }
        div div {
          color: white;
        }
      }
      .group {
        color: white;
        .toggle {
          background-color: #3F3F3F;
          &.active {
            background-color: #423AA5;
          }
        }
      }
      .control-label {
        color: white;
      }
      .form-control {
        background: #423AA5;
        color: white;
        border-color: #8269E3;
      }
      .projects {
        h3 {
          color: white;
        }
      }
      .success {
        color: white;
        a {
          border-color: white;
          color: white;
        }
      }
    }
  }
  &.route-admin-security {
    section {

    }
    h3 {
      color: #D8D8D8;
    }
    .group {
      color: white;
      border-bottom-color: #7D7D7D;
      .toggle {
        background-color: #3F3F3F;
      }
    }
  }
  &.route-admin-premissions {
    h3 {
      color: #D8D8D8;
    }
    .nav-tabs {
      .nav-item {
        color: rgba(white, .5);
        &.active {
          color: white;
          border-bottom-color: #818DFE;
        }
      }
    }
    .tab-content {
      background-color: transparent;
    }
    .group {
      color: white;
      border-bottom-color: #7D7D7D;
      .toggle {
        background-color: #3F3F3F;
        &.active {
          background-color: #423AA5;
        }
      }
    }
  }
  &.route-admin-logs {
    .logs-list {
      background-color: transparent;
    }
    .form-group-inset-label {
      .icon {
        border-right-color: #8269E3;
      }
      .form-label {
        color: #929292;
      }
      .form-control {
        background: #423AA5;
        color: white;
        border-color: #8269E3;
      }
    }
    section {
      .logs-list {
        .log {
          border-bottom-color: #7D7D7D;
          & > div > div,
          & > strong {
            color: white;
          }
        }
      }
    }
  }
  &.route-admin-services {
    section {
      h3 {
        color: white;
      }
      .nav-tabs {
        .nav-item {
          color: rgba(white, .5);
          &.active {
            color: white;
            border-bottom-color: #818DFE;
          }
        }
      }
      .tab-content {
        background-color: transparent;
      }
      .connected {
        border-bottom-color: #717171;
        & > strong {
          color: white;
          & + div {
            div {
              span {
                color: #D8D8D8;
                &.con {
                  border: 1px solid #5AC53A;
                  border-radius: 10px;
                  font-weight: normal;
                  font-size: 13px;
                  line-height: 16px;
                  color: #5AC53A;
                  padding: 3px 7px;
                }
                &.discon {
                  border: 1px solid #DF5353;
                  border-radius: 10px;
                  font-weight: normal;
                  font-size: 13px;
                  line-height: 16px;
                  color: #DF5353;
                  padding: 3px 7px;
                }
                &.incomplete {
                  border: 1px solid #717171;
                  border-radius: 10px;
                  font-weight: normal;
                  font-size: 13px;
                  line-height: 16px;
                  color: #717171;
                  padding: 3px 7px;
                }
              }
            }
          }
        }
      }
      .is-edit {
        background-color: #25295B;
        .cancel {
          color: #7D89FC;
        }
        .form-control {
          background: #423AA5;
          border: 1px solid #DDDDDD;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
          color: white;
        }
        .actions {
          .btn {
            &.btn-delete {
              color: #ED6D6D;
            }
            &.btn-update {
              background: #626DD8;
              box-shadow: 0px 0px 10px #7D89FC;
              color: white;
            }
          }
        }
      }
    }
  }
}
