.route-search {
  .row {
    min-height: 100vh;
  }
  h1 {
    font-weight: normal;
    font-size: 28px;
    line-height: 75px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
  }

  .project-list {
    padding-bottom: 95px;
    .project {
      background: #FFFFFF;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      padding: 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      button {
        background: none transparent;
        border: none;
        padding: 24px 10px 24px 0;
        margin: -15px;
        position: relative;
        z-index: 1;
        span {
          border-radius: 100px;
          font-weight: normal;
          font-size: 13px;
          text-align: center;
          display: block;
          padding: 1px 7px 1px 25px;
          border: 1px solid #7D89FC;
          &.favorite {
            background: #7D89FC;
            color: #FFFFFF;
            background-image: url('../assets/icon_star.png');
            background-repeat: no-repeat;
            background-position: 6px 3px;
          }
          &.added {
            background: #FFFFFF;
            color: #7D89FC;
            padding-right: 16px;
            background-image: url('../assets/icon_check.png');
            background-repeat: no-repeat;
            background-position: 8px center;
          }
        }
      }
      strong {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #717171;
        display: block;
        margin-bottom: 0;
      }
      small {
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #717171;
        max-width: 225px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      & + .project {
        margin-top: 15px;
      }
    }
  }
}

// Dark theme
.dark-mode {
  &.route-search {
    .form-group-inset-label {
      .icon {
        border-right-color: #8269E3;
      }
      .form-label {
        color: #929292;
      }
      .form-control {
        background: #423AA5;
        color: white;
        border-color: #8269E3;
      }
    }
    .project-list {
      padding-bottom: 95px;
      .project {
        background: linear-gradient(90deg, #423AA5 2.19%, #3F1C92 100%);
        strong {
          color: white;
        }
        small {
          color: #D8D8D8;
        }
      }
    }
  }
}
