.route-project-details-t2 {
  .layout > .row {
    min-height: 100vh;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 65px;
    h1 {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.02em;
      color: #FFFFFF;
      display: inline-block;
      margin: 0;
      position: relative;
      top: 6px;
      small {
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #D8D8D8;
        display: block;
        width: 170px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .back {
      color: white;
      font-weight: normal;
      display: inline-block;
      margin-top: 22px;
      margin-right: 13px;
      width: 10px;
      height: 20px;
      background-position: left center;
      background-repeat: no-repeat;
      background-image: url('../assets/icon_back.png');
    }
    .users {
      margin-top: 15px;
      display: block;
      span {
        display: inline-block;
        height: 34px;
        width: 34px;
        text-align: center;
        border-radius: 50%;
      }
      .user {
        border: 3px solid #7b3bf5;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 28px;
        vertical-align: middle;
        background-color: #18A0FB;
        color: white;
        margin-right: -10px;
        position: relative;
        z-index: 5;
        & + .user {
          background-color: #7D89FC;
          z-index: 4;
          & + .user {
            background-color: #E8637B;
            z-index: 3;
            & + .user {
              background-color: #7B39F5;
              z-index: 2;
            }
          }
        }
      }
      .add {
        background-color: #E9ECEE;
        color: #717171;
        line-height: 26px;
        font-size: 20px;
        border: 3px solid #7b3bf5;
        vertical-align: middle;
        position: relative;
        z-index: 1;
      }
    }
  }
  section {
    background-color: white;
    min-height: calc(100vh - 65px);
    margin-left: -15px;
    margin-right: -15px;
    padding: 0 15px 95px;
    & > .row {
      margin-left: -5px;
      margin-right: -5px;
      & > .col {
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    h3 {
      font-weight: bold;
      font-size: 11px;
      line-height: 30px;
      letter-spacing: 0.07em;
      color: #909090;
      text-transform: uppercase;
    }

    .bg {
      background: #F2F2F2;
      border-radius: 16px;
      padding: 15px;
      margin-bottom: 10px;
      display: block;
      h3 {
        margin: 0 0 8px;
        font-weight: bold;
        font-size: 11px;
        line-height: 1;
        letter-spacing: 0.07em;
        color: #929292;
      }
    }

  }
}

.route-project-details-t2 {
  &.cost {

    #summary-chart {
      .highcharts-credits {
        display: none;
      }
      .highcharts-tooltip {
        text-align: center;
        border: none;
        & > span {
          background-color: white;
        }
      }
      .date {
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        color: #717171;
      }
      .values {
        display: inline-block;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #18A0FB;
        b {
          font-weight: normal;
          text-transform: uppercase;
        }
        & + .values {
          color: #7B39F5;
        }
      }
    }

    .summary-legend {
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      color: #717171;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 15px;
      padding: 0 15px;
      span {
        width: 25px;
        height: 3px;
        display: inline-block;
        margin-right: 7px;
        position: relative;
        top: -3px;
      }
      .actual span {
        background-color: #18A0FB;
      }
      .forecast span {
        height: 0;
        border-top: 3px dashed #18A0FB;
      }
      .budget span {
        background-color: #7B39F5;
      }
    }

    .summary-units {
      background: #DDDDDD;
      border: 2px solid #DDDDDD;
      border-radius: 6px;
      padding: 2px;
      display: flex;
      flex-direction: row;
      margin-top: 15px;
      margin-bottom: 20px;
      .btn {
        box-shadow: none;
        border-radius: 5px;
        border: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        padding: 8px;
        color: #717171;
        display: block;
        width: 100%;
        &.active {
          background: #7D89FC;
          color: white;
          &:focus,
          &:active,
          &:active:focus {
            outline: none;
            box-shadow: none;
          }
        }
        &:focus,
        &:active,
        &:active:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }

    .summary-timeframe {
      background: #DDDDDD;
      border: 2px solid #DDDDDD;
      border-radius: 6px;
      padding: 2px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 15px;
      .btn {
        box-shadow: none;
        border-radius: 5px;
        border: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        padding: 6px;
        color: #717171;
        display: block;
        width: 20%;
        &.active {
          background: #7D89FC;
          color: white;
          &:focus,
          &:active,
          &:active:focus {
            outline: none;
            box-shadow: none;
          }
        }
        &:focus,
        &:active,
        &:active:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }

    .budget {
      & > div {
        margin-bottom: 10px;
        strong {
          font-weight: normal;
          font-size: 22px;
          line-height: 1;
          letter-spacing: 0.07em;
          color: #717171;
          text-transform: uppercase;
        }
        div {
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          color: #707781;
        }
      }
      .bar {
        padding: 2px;
        height: 16px;
        background-color: #C4C4C4;
        border-radius: 10px;
        margin: 0;
        div {
          background: #5AC53A;
          border-radius: 10px;
          text-align: right;
          font-size: 9px;
          color: white;
          letter-spacing: 0.07em;
          height: 12px;
          line-height: 12px;
        }
      }
    }

    .variance {
      span {
        display: block;
        font-weight: normal;
        font-size: 22px;
        line-height: 30px;
        letter-spacing: 0.07em;
        color: #717171;
      }
      div {
        font-weight: normal;
        font-size: 22px;
        line-height: 30px;
        letter-spacing: 0.07em;
        color: #ED6D6D;
      }
      small {
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #707781;
        display: block;
        margin-top: 42px;
      }
    }

  }

  &.schedule {

    .timeline {
      & > strong {
        font-weight: normal;
        font-size: 22px;
        line-height: 30px;
        color: #717171;
        display: block;
        margin-bottom: 45px;
      }
      .details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        & > div {
          flex-grow: 1;
          width: 50%;
          text-transform: uppercase;
          &:last-child {
            text-align: right;
          }
          b {
            display: block;
            font-weight: bold;
            font-size: 13px;
            letter-spacing: normal;
            color: #717171;
          }
          span {
            font-weight: normal;
            font-size: 13px;
            letter-spacing: normal;
            color: #707781;
            display: block;
          }
        }
      }
    }

    .buffer {
      span {
        display: block;
        font-weight: normal;
        font-size: 22px;
        color: #717171;
        small {
          display: block;
          font-weight: normal;
          font-size: 13px;
          color: #717171;
        }
      }
      .bar {
        margin-top: 24px;
        .legend {
          color: #707781;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-size: 10px;
          margin-bottom: 10px;
        }
        .chart {
          height: 12px;
          background: #5AC53A;
          border-radius: 10px 0 0 10px;
          width: 55%;
          position: relative;
          &:after {
            content: '';
            height: 24px;
            border-left: 1px solid #7D7D7D;
            position: absolute;
            right: 0;
            top: -6px;
          }
        }
      }
    }

    .velocity {
      p {
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #717171;
      }
      .chart {
        height: 40px;
        border-radius: 10px;
        position: relative;
    //    background-image: linear-gradient(#ED6D6D,#ED6D6D), linear-gradient(#EDD86D,#EDD86D), linear-gradient(#5AC53A,#5AC53A);
    //    background-size: 42% 100%, 62% 100%, 100% 100%;
        background-color: white;
        background-repeat: no-repeat;
        position: relative;
        margin-bottom: 45px;
        .value {
          background: #5AC53A;
          border-radius: 0px 10px 10px 0px;
          height: 18px;
          padding: 2px 8px;
          position: absolute;
          left: 0;
          top: 11px;
          z-index: 1;
          color: white;
          font-size: 9px;
          text-align: right;
        }
        .commitment {
          position: absolute;
          font-weight: normal;
          font-size: 10px;
          line-height: 12px;
          color: #707781;
          top: -30px;
          right: 0;
          &:after {
            content: '';
            height: 16px;
            border-left: 1px solid #7D7D7D;
            position: absolute;
            right: 0;
            bottom: -18px;
          }
        }
        .completed {
          position: absolute;
          font-weight: normal;
          font-size: 10px;
          line-height: 12px;
          color: #707781;
          bottom: -20px;
          right: 40px;
          &:after {
            content: '';
            height: 20px;
            border-left: 1px solid #7D7D7D;
            position: absolute;
            right: 0;
            top: -22px;
          }
        }
      }
      a {
        display: block;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #626DD8;
        padding: 13px;
        background-color: white;
        border: 1px solid #626DD8;
        box-sizing: border-box;
        box-shadow: 0px 0px 10px #7D89FC;
        border-radius: 10px;
      }
    }

    .milestones {
      .milestone {
        & + .milestone {
          margin-top: 24px;
        }
        .status-grey {
          float: right;
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          color: white;
          background: #A0A1A3;
          border-radius: 10px;
          padding: 4px 10px;
        }
        .status-green {
          float: right;
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          color: white;
          background: #55BF3B;
          border-radius: 10px;
          padding: 4px 10px;
        }
        .status-yellow {
          float: right;
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          color: white;
          background: #DDDF0D;
          border-radius: 10px;
          padding: 4px 10px;
        }
        .status-red {
          float: right;
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          color: white;
          background: #DF5353;
          border-radius: 10px;
          padding: 4px 10px;
        }
        .status-blue {
          float: right;
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          color: white;
          background: #27A2F8;
          border-radius: 10px;
          padding: 4px 10px;
        }
        strong {
          display: block;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          color: #717171;
        }
        div {
          font-weight: normal;
          font-size: 11px;
          line-height: 13px;
          color: #707781;
          span {
            display: inline-block;
            margin-right: 10px;
          }
        }
      }
    }

  }
  &.scope {

    .scope-progress {
      a {
        display: block;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #626DD8;
        padding: 13px;
        background-color: white;
        border: 1px solid #626DD8;
        box-sizing: border-box;
        box-shadow: 0px 0px 10px #7D89FC;
        border-radius: 10px;
        margin-top: 30px;
      }
      .lenged {
        margin: 15px 0 0;
        padding: 0;
        list-style: none;
        li {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          strong {
            font-weight: normal;
            font-size: 22px;
            line-height: 30px;
            text-align: right;
            color: #717171;
          }
          div {
            font-weight: normal;
            font-size: 13px;
            line-height: 16px;
            color: #707781;
            span {
              display: inline-block;
              width: 18px;
              height: 18px;
              background: #5AC53A;
              border-radius: 4px;
              position: relative;
              top:4px;
              margin-right: 4px;
            }
          }
          & + li {
            margin-top: 5px;
          }
          &:nth-child(2) {
            div span {
              background-color: #FCB700;
            }
          }
          &:nth-child(3) {
            div span {
              background-color: #7D89FC;
            }
          }
        }
      }
      .highcharts-container  {
        .highcharts-credits {
          display: none;
        }
        .highcharts-label.highcharts-tooltip {
          position: absolute;
          top: 39% !important;
          left: 38% !important;
          .pie-tooltip {
            width: 75px;
            text-align: center;
            color: #717171;
            .name {
              font-weight: normal;
              font-size: 13px;
              line-height: 16px;
            }
            .value {
              font-weight: normal;
              font-size: 22px;
              line-height: 30px;
              display: block;
            }
          }
        }
      }
    }

    .requests {
      strong {
        display: block;
        font-weight: normal;
        font-size: 22px;
        line-height: 30px;
        color: #717171;
        margin-bottom: 20px;
      }
      .high,
      .medium,
      .low {
        background-position: left center;
        background-repeat: no-repeat;
        padding-left: 30px;
        div {
          background-color: white;
          padding: 1.5px 5px;
          text-align: right;
          border-radius: 0 10px 10px 0;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
        }
      }
      .high {
        background-image: url('../assets/High.png');
        div {
          color: #ED6D6D;
        }
      }
      .medium {
        margin:5px 0;
        background-image: url('../assets/Medium.png');
      }
      .low {
        background-image: url('../assets/Low.png');
        div {
          color: #18A0FB;
        }
      }
    }

    .overview {
      div {
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #707781;
        min-height:42px;
        strong {
          color: #717171;
          font-weight: normal;
          font-size: 22px;
          line-height: 30px;
          letter-spacing: 0.07em;
        }
      }
    }

    .tracking {
      .group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        & + .group {
          margin-top: 15px;
        }
        div > div {
          font-weight: normal;
          font-size: 11px;
          line-height: 13px;
          padding-left: 38px;
          margin-top: 1px;
        }
        div div span {
          display: inline-block;
          width: 50px;
        }
        strong {
          margin-left:-38px;
          color: #717171;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
        }
        .author {
          display: inline-block;
          width: 28px;
          height: 28px;
          background: #E8637B;
          text-align: center;
          line-height: 28px;
          color: white;
          border-radius: 50%;
          font-size: 10px;
          margin-right: 10px;
          margin-bottom: -10px;
          position: relative;
          top: 8px;
          left: -38px;
        }
        .status {
          display: inline-block;
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          color: white;
          background: #A0A1A3;
          border-radius: 10px;
          padding: 4px 10px;
          min-width: 85px;
        }
        .blue {
          background: #18A0FB;
        }
        .green {
          background: #5AC53A;
        }
        .grey {
          background: #A0A1A3;
        }
        .yellow {
          background: #FCB700;
        }
        .priority {
          margin-top: 5px;
          display: inline-block;
          text-align: right;
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          color: #707781;
          background-position: left center;
          background-repeat: no-repeat;
          padding-left: 20px;
          &.High {
            background-image: url('../assets/High.png');
          }
          &.Low {
            background-image: url('../assets/Low.png');
          }
          &.Medium {
            background-image: url('../assets/Medium.png');
          }
        }
      }
    }

  }
  &.overall {
    .chart-link {
      display: block;
      padding: 10px 15px;
      margin: 0 -15px;
      border-top: 1px solid #707781;
      background-repeat: no-repeat;
      background-position: right 15px top 11px;
      background-image: url('../assets/icon_arrow-right.png');
      &:last-child {
        border-bottom: 1px solid #707781;
      }
      h3 {
        text-transform: none;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: normal;
      }
      & > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .trend {
          display: block;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 11px;
          line-height: 13px;
          letter-spacing: 0.07em;
          color: #909090;
          margin-top: 2px;
          .up {
            display: inline-block;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid #909090;
            margin-left: 4px;
          }
          .down {
            display: inline-block;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 7px solid #909090;
            margin-left: 4px;
          }
          .neutral {
            background-color: #909090;
            display: inline-block;
            width: 15px;
            height: 2px;
            margin-left: 4px;
            position: relative;
            top: -2px;
          }
        }
        .health {
          border-radius: 10px;
          color: white;
          font-weight: bold;
          font-size: 11px;
          line-height: 20px;
          text-align: center;
          letter-spacing: 0.07em;
          padding: 2px;
          min-width: 65px;
          height: 24px;
          margin-right: 20px;
        }
        .highcharts-container  {
          .highcharts-credits {
            display: none;
          }
          .trend-data-label {
            border-radius: 50%;
            margin: -4px -4px 0 0;
            width: 8px;
            height: 8px;
            display: block;
            text-align: center;
            color: rgba(0,0,0,0);
            text-indent: -200%;
            white-space: nowrap;
            overflow: hidden;
            background-color: white !important;
          }
        }
      }
    }
    .btn {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      width: 100%;
      padding: 13px;
      border-radius: 10px;
      margin-top: 30px;
      margin-bottom: 60px;
      color: #626DD8;
      background-color: white;
      border: 1px solid #626DD8;
      box-shadow: 0px 0px 10px #7D89FC;
    }
  }

  &.risk {
    .tabs {
      .nav-tabs {
        border: none;
        .nav-link {
          border-width: 0;
          border-bottom: 2px solid transparent;
          border-radius: 0;
          padding: 0;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          color: #707781;
          &.active {
            color: #717171;
            border-bottom-color: #412899;
            background: transparent;
          }
          & + .nav-link {
            margin-left: 30px;
          }
        }
      }
      ul {
        margin: 15px 0 10px;
        padding: 0;
        list-style: none;
        li {
          display: block;
          position: relative;
          padding-left: 100px;
          margin-bottom: 10px;
          .label {
            display: inline-block;
            font-weight: normal;
            font-size: 13px;
            line-height: 16px;
            text-align: right;
            position: absolute;
            width: 90px;
            top: 6px;
            left: 0;
          }
          .chart {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            div {
              color: white;
              height: 28px;
              background-color: #EDD86D;
              text-align: center;
              line-height: 24px;
              font-size: 16px;
              span {
                display: inline-block;
                padding: 2px;
              }
              &:nth-child(1) {
                background-color: #5AC53A;
              }
              &:nth-child(2) {
                background-color: #EDD86D;
              }
              &:nth-child(3) {
                background-color: #ED6D6D;
              }
            }
          }

        }
      }
      .lenged {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        div {
          color: #707781;
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          span {
            display: inline-block;
            background: #5AC53A;
            border-radius: 4px;
            width: 18px;
            height: 18px;
            margin-right: 5px;
            position: relative;
            top: 4px;
          }
          &:nth-child(2) span {
            background-color: #EDD86D;
          }
          &:nth-child(3) span {
            background-color: #ED6D6D;
          }
        }
      }
      .heatmap {
        position: relative;
        margin-top: 15px;
        .map {
          width: 275px;
          margin-left: 40px;
          height: 190px;
          background: linear-gradient(64.25deg, #5AC53A 0%, #72C842 0.01%, #EDD86D 51.18%, #ED6D6D 100%);
          border-radius: 8px;
          position: relative;
          .values {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            .group {
              height: calc(100% / 3);
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              text-align: center;
              div {
                width: calc(100% / 3);
                font-size: 16px;
                text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
                color: white;
                padding: 20px 0;
                & + div {
                  border-left: 1px solid #25295B;
                }
              }
              & + .group {
                border-top: 1px solid #25295B;
              }
            }
          }
        }
        .legend {
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          text-align: center;
          color: #717171;
          &.left {
            position: absolute;
            transform: rotate(-90deg);
            top: 85px;
            left: -25px;
          }
          &.bottom {
            margin-top: 5px;
            margin-left: 40px;
          }
        }
        .labels {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          &.bottom {
            margin-left: 40px;
            margin-top: 5px;
          }
          &.right {
            position: absolute;
            transform: rotate(-90deg);
            top: 88px;
            left: -65px;
            width: 190px;
          }
          div {
            width: 33%;
            text-align: center;
            font-weight: normal;
            font-size: 9px;
            line-height: 11px;
            color: #707781;
          }
          &.bottom-large {
            width: 19%;
            justify-content:left;
            text-align: left;
            margin-left: 40px;
            margin-top: 5px;
          }
        }
      }
    }

    .status {
      h3 {
        text-align: center;
        border-bottom: 1px solid #707781;
        margin: -5px -15px 15px;
        padding-bottom: 10px;
      }
      .red,
      .green,
      .yellow {
        margin: 15px 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        strong {
          display: inline-block;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          width: 80px;
        }
        & > div {
          flex-grow: 1;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          div {
            height: 12px;
            border-radius: 10px;
            display: inline-block;
            margin: 0 5px;
            padding: 0 2px;
          }
        }
        span {
          display: inline-block;
          letter-spacing: 0.07em;
          font-weight: bold;
          font-size: 9px;
          color: #707781;
        }
      }
      .red {
        color: #ED6D6D;
        div div {
          background-color: #ED6D6D;
        }
      }
      .green {
        color: #5AC53A;
        div div {
          background-color: #5AC53A;
        }
      }
      .yellow {
        color: #FCB700;
        div div {
          background-color: #FCB700;
        }
      }
    }
  }

  &.procurement {
    .spend {
      strong {
        font-size: 22px;
        font-weight: normal;
        color: #717171;
        display: block;
      }
      p {
        margin: 43px 0 0;
        font-size: 13px;
        color: #707781;
      }
    }
    .suppliers {
      strong {
        font-size: 22px;
        font-weight: normal;
        color: #717171;
        display: block;
      }
      div {
        font-size: 13px;
        color: #707781;
        span {
          display: inline-block;
          width: 18px;
          height: 18px;
          border-radius: 4px;
          color: white;
          text-align: center;
          line-height: 18px;
          font-size: 16px;
          background-color: #5AC53A;
          margin-right: 3px;
        }
        & + div {
          span {
            background-color: #7D89FC;
          }
          & + div {
            span {
              background-color: #18A0FB;
            }
            & + div {
              span {
                background-color: #E8637B;
              }

            }
          }
        }
      }
    }
    .filters {
      .btn {
        display: block;
        width: 100%;
        text-align: center;
    //    background: #0D0E3F;
        border: 1px solid #626DD8;
        box-sizing: border-box;
        box-shadow: 0px 0px 6px #7D89FC;
        border-radius: 10px;
        color: #7D89FC;
        padding: 2px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 11px;
        line-height: 30px;
        letter-spacing: 0.07em;
        margin-bottom: 10px;
      }
    }
    .list {
      padding: 0;
      .transaction {
        padding: 15px;
        .status {
          float: right;
          background: #5AC53A;
          border-radius: 10px;
          color: white;
          font-size: 13px;
          padding: 2px 6px;
          width: 65px;
          text-align: center;
        }
        strong {
          color: #717171;
          font-weight: bold;
          font-size: 13px;
        }
        div {
          color: #707781;
          font-size: 11px;
          span {
            display: inline-block;
            width: 80px;
          }
        }
        & + .transaction {
          border-top: 1px solid rgba(black, 0.1);
        }
      }
    }
    .notice {
      & > div {
        padding-left: 53px;
        position: relative;
        strong {
          font-weight: bold;
          display: block;
          color: #717171;
          font-size: 16px;
          line-height: 18px;
        }
        span {
          color: #707781;
          font-size: 12px;
        }
        &:after {
          content: '';
          position: absolute;
          left: 1px;
          top: 1px;
          width: 38px;
          height: 38px;
          background-color: #7D89FC;
          border-radius: 50%;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url('../assets/icon_warrning.png');
        }
        & + div {
          margin-top: 15px;
        }
      }
    }
  }

}

// Dark theme
.dark-mode {
  &.route-project-details-t2 {
    .header {
      h1 {
        color: #FFFFFF;
      }
      .back {
        color: white;
      }
      .users {
        .user {
          border-color: #142776;
        }
        .add {
          border-color: #0D0E3F;
        }
      }
    }
    section {
      background: transparent;
      h3 {
        color: white;
      }
      .bg {
        background: #25295B;
        h3 {
          color: #929292;
        }
      }
    }

    &.overall {
      .btn {
        background: #0D0E3F;
        border: 1px solid #626DD8;
        box-shadow: 0px 0px 10px #7D89FC;
        color: #7D89FC;
      }
    }

    &.cost {
      #summary-chart {
        .highcharts-tooltip {
          & > span {
            background-color: #0D0E3F;
          }
        }
        .date {
          color: white;
        }
      }
      .summary-legend {
        color: white;
      }
      .summary-units {
        background: #0D0E3F;
        border-color: #412899;
        .btn {
          color: #929292;
          &.active {
            background: #412899;
            color: white;
          }
        }
      }
      .summary-timeframe {
        background: #0D0E3F;
        border-color: #412899;
        .btn {
          color: #929292;
          &.active {
            background: #412899;
            color: white;
          }
        }
      }
      .budget {
        & > div {
          strong {
            color: white;
          }
          div {
            color: #D8D8D8;
          }
        }
      }
      .variance {
        span {
          color: white;
        }
        small {
          color: #D8D8D8;
        }
      }
    }

    &.schedule {
      .timeline {
        & > strong {
          color: white;
        }
        .details {
          & > div {
            b {
              color: white;
            }
            span {
              color: #D8D8D8;
            }
          }
        }
      }
      .buffer {
        span {
          color: white;
          small {
            color: #D8D8D8;
          }
        }
        .bar {
          .legend {
            color: #D8D8D8;
          }
        }
      }
      .velocity {
        p {
          color: #D8D8D8;
        }
        .chart {
          background-color: #0f0f4e;
        }
        .chart .commitment,
        .chart .completed {
          color: #D8D8D8;
        }
        a {
          color: #626DD8;
          background-color: #0D0E3F;
          border: 1px solid #626DD8;
        }
      }
      .milestones {
        .milestone {
          strong {
            color: white;
          }
          div {
            color: #D8D8D8;
          }
        }
      }
    }

    &.scope {
      .scope-progress {
        a {
          color: #626DD8;
          background-color: #0D0E3F;
          border: 1px solid #626DD8;
          box-shadow: 0px 0px 10px #7D89FC;
        }
        .lenged {
          li {
            strong {
              color: white;
            }
            div {
              color: white;
            }
          }
        }
        .highcharts-container  {
          .highcharts-label.highcharts-tooltip {
            .pie-tooltip {
              color: white;
            }
          }
        }
      }
      .requests {
        strong {
          color: white;
        }
      }
      .overview {
        div {
          color: white;
          strong {
            color: #D8D8D8;
          }
        }
      }

      .tracking {
        .group {
          color: #D8D8D8;
          strong {
            color: white;
          }
          .priority {
            color: #D8D8D8;
          }
        }
      }
    }

    &.risk {
      .tabs {
        .nav-tabs {
          border: none;
          .nav-link {
            color: rgba(white, 0.5);
            &.active {
              color: white;
            }
          }
        }
        ul li .label {
          color: white;
        }
        .lenged {
          div {
            color: white;
          }
        }
        .heatmap {
          .legend {
            color: #D8D8D8;
          }
          .labels {
            div {
              color: #707781;
            }
          }
        }
      }
    }

    &.procurement {
      .spend {
        strong {
          color: white;
        }
        p {
          color: #D8D8D8;
        }
      }
      .suppliers {
        strong {
          color: white;
        }
        div {
          color: #D8D8D8;
        }
      }
      .filters {
        .btn {
          background: #0D0E3F;
          border: 1px solid #626DD8;
          box-shadow: 0px 0px 6px #7D89FC;
          color: #7D89FC;
        }
      }
      .list {
        .transaction {
          strong {
            color: white;
          }
          div {
            color: #D8D8D8;
          }
        }
      }
      .notice {
        & > div {
          strong {
            color: white;
          }
          span {
            color: #D8D8D8;
          }
        }
      }
    }

  }
}
